const getMonthsFromDuration = (duration) => {
  const regex = new RegExp(/(P)(\d+)(M|Y)/, 'gi');
  if (!duration || !duration.match(regex)) return 0;

  const [period, unit] = duration?.split?.(regex)?.filter?.((e) => !!e && e?.toUpperCase?.() !== 'P');
  const multiplier = unit?.toUpperCase?.() === 'Y' ? 12 : 1;

  return period * multiplier;
};

export default getMonthsFromDuration;
