export default {
  FARMSTAND: 'Farmstand',
  SEEDLINGS: 'Seedlings',
  SUPPLIES: 'Supplies',
  EXTENSION: 'Extension Kits',
  GIFT: 'Gift Card',
  PACKS: 'Plant Packs',
  BUNDLES: 'PlantBundle',
  CUSTOM_BUNDLES: 'PlantBundleMix',
  SUBSCRIPTION: 'Subscription',
};
