import { createSlice, createSelector } from '@reduxjs/toolkit';

/**
 * * Modal - Redux Reducer - controls a single modal opened at a time, records opened history of modal types
 *
 * @param state
 *
 */

export const MODALS = {
  CLIMATE: 'climate',
  VERIFY_ADDRESS: 'verifyAddress',
  PASSWORD_RESET: 'passwordReset',
  LOGIN: 'login',
  ERROR: 'error',
  REFERRAL: 'referral',
  GROW_ENVIRONMENT: 'growEnvironment',
  INTERNATIONAL: 'international',
  GIFT_CHECKOUT: 'giftCheckout',
  GIFT_CHECKOUT_SUBSCRIPTION: 'giftCheckoutSubscription',
  FARMSTAND_FORM: 'farmstandForm',
};

const initialState = {
  open: '',
  climate: { id: MODALS.CLIMATE, hasBeenOpen: false },
  verifyAddress: { id: MODALS.VERIFY_ADDRESS, hasBeenOpen: false },
  passwordReset: { id: MODALS.PASSWORD_RESET, hasBeenOpen: false },
  login: { id: MODALS.LOGIN, hasBeenOpen: false },
  error: { id: MODALS.ERROR, label: 'Unexpected Error', hasBeenOpen: false },
  referral: { id: MODALS.REFERRAL, hasBeenOpen: false },
  growEnvironment: { id: MODALS.GROW_ENVIRONMENT, hasBeenOpen: false },
  international: { id: MODALS.INTERNATIONAL, hasBeenOpen: false },
  giftCheckout: { id: MODALS.GIFT_CHECKOUT, hasBeenOpen: false },
  giftCheckoutSubscription: { id: MODALS.GIFT_CHECKOUT_SUBSCRIPTION, hasBeenOpen: false },
  farmstandForm: { id: MODALS.FARMSTAND_FORM, hasBeenOpen: false },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setLabel(state, { payload }) {
      state[payload.id].label = payload.label;
    },
    setOpenModal(state, { payload }) {
      state[payload] = Object.assign({}, state[payload], { hasBeenOpen: true });
      state.open = payload;
    },
    setCloseModal(state) {
      state.open = '';
    },
  },
});

export const getOpenModal = createSelector([(state) => state.modal], (modal) => modal[modal.open] || {});

const { actions, reducer } = modalSlice;

export const { setLabel, setOpenModal, setCloseModal } = actions;

export default reducer;
