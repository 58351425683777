import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const FixedOneClickPayment = styled.div.attrs({
  className: 'FixedOneClickPayment',
})`
  position: fixed;
  z-index: 15;
  bottom: 0px;
  left: 0px;
  width: 100%;
  cursor: pointer;

  @media (hover: hover) {
    user-select: none;
  }

  &.show {
    .SectionAnimated {
      transform: translateY(-99%);

      .SectionTop {
        .Text {
          visibility: hidden;
        }
      }
    }

    .SectionBottom {
      .WrapOrderBtn {
        @media ${breakPoints.large_break} {
          right: calc(50% - 152px);
        }
      }

      .WrapPersonalize {
        opacity: 0;
      }

      #BackToFarm {
        opacity: 0;
      }
    }
  }
`;

export const SectionAnimated = styled.div.attrs({
  className: 'SectionAnimated',
})`
  transition: all 0.4s ease-out;
  transform: translateY(-40px);
  width: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.farmProject.gray_3};
  box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.1);

  .Icon {
    margin: 0 auto;
  }
`;

export const SectionTop = styled.div.attrs({
  className: 'SectionTop',
})`
  padding-top: 0.5rem;

  .Icon {
    margin: 0 auto;
  }
  .Text {
    font-size: 0.5rem;
    line-height: normal;

    @media ${breakPoints.large_break} {
      font-size: 0.625rem;
    }
  }
`;

export const SectionMiddle = styled.div.attrs({
  className: 'SectionMiddle',
})`
  padding: 0 1.25rem;

  @media ${breakPoints.large_break} {
    padding-bottom: 1rem;
  }

  .OrderSummary {
    background-color: ${({ theme }) => theme.farmProject.gray_3};

    @media ${breakPoints.large_break} {
      width: 26.125rem;
      margin: 0 auto;
    }

    .CheckoutHeader {
      display: none;
    }
    .ItemsContainer {
      margin-top: 0;
    }
    .FooterActions {
      display: none;
    }
  }
`;

export const SectionBottom = styled.div.attrs({
  className: 'SectionBottom',
})`
  width: 100%;
  background-color: ${({ theme }) => theme.farmProject.gray_3};
  position: relative;

  @media ${breakPoints.large_break} {
    display: flex;
    justify-content: space-between;
    padding: ${(props) => (props.isFYF ? '1rem' : '0')} 5rem;
    flex-wrap: wrap;
    padding-bottom: 0.5rem;
    box-sizing: border-box;
  }

  #BackToFarm {
    display: none;
    border: 1px solid ${({ theme }) => theme.farmProject.gray};
    border-radius: 3px;

    @media ${breakPoints.large_break} {
      display: flex;
      max-height: 50px;
      transition: all 0.4s ease-out;

      .Icon {
        svg {
          width: 1.75rem;
          height: 1.75rem;
        }
      }

      .Text {
        width: 80px;
        line-height: 1rem;
        margin-left: 1rem;
        text-align: left;
      }
    }
  }
`;

export const WrapOrderBtn = styled.div.attrs({
  className: 'WrapOrderBtn',
})`
  text-align: center;
  padding-bottom: 0.75rem;
  transition: all 0.4s ease-out;

  @media ${breakPoints.large_break} {
    position: relative;
    right: 0px;
  }

  .Button {
    margin: 0 auto;
    margin-bottom: 0.5rem;
    min-width: 15.375rem;
    justify-content: space-between;

    &.Checkout {
      justify-content: center;
    }

    span {
      font-size: 0.875rem;
    }

    @media ${breakPoints.large_break} {
      min-width: 19rem;
      height: 3.15rem;

      span {
        font-size: 1rem;
      }
    }
  }

  .Text {
    @media ${breakPoints.large_break} {
      font-size: 0.8125rem;
    }
  }
`;

export const BgOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgba(56, 58, 54, 0.15);
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

export const SummaryOrder = styled.div`
  max-width: 26rem;
  margin: 0 auto;
  padding-top: 0.5rem;
  padding-bottom: 1.75rem;

  p {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }

  .OrderDetail {
    .Name {
      margin-left: 1.5rem;
      margin-right: auto;
    }
  }

  hr {
    margin-top: 2.25rem;
  }

  .SubTotal {
    margin-bottom: 0.25rem;
  }

  .Shipping {
    margin-bottom: 0.25rem;
  }

  .Tax {
    margin-bottom: 0.25rem;

    small {
      font-size: 0.625rem;
      line-height: 0.75rem;
      font-style: italic;
    }
  }

  .Total {
    p {
      font-size: 0.9375rem;
      line-height: 1.125rem;
    }
  }
`;

export const WrapPersonalize = styled.div.attrs({
  className: 'WrapPersonalize',
})`
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  width: 13.25rem;
  border: 1px solid ${({ theme }) => theme.farmProject.gray};
  border-radius: 3px;
  display: none;
  flex-wrap: wrap;
  padding: 0.75rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.25s ease-in-out;

  @media ${breakPoints.large_break} {
    display: flex;
  }

  .Label {
    font-size: 0.875rem;
    line-height: 1rem;
    width: 6.25rem;
  }
  .Amount {
    font-size: 1.5rem;
    font-weight: 300;
  }
`;

export const WrapLoading = styled.div`
  svg {
    animation: rotation 3s infinite linear;
    width: 3rem;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
