import {
  addItem,
  replaceSeedlings,
  removeItem,
  removeItemOfType,
  removeItemsOfCategories,
  updateItem,
  setDiscount,
  removeDiscount,
  cartPreview,
  addBulkItems,
  addBulkItemsAndDiscount,
  addBulkItemsAndDiscountArray,
  closeModal,
  openCartModal,
  openOrderSummaryModal,
  getCartHasFarmstand,
  loadCartPreview,
} from 'reduxState/cart';
import { getLoginState, setMyFarm, userLogout, getCAPIUserInfo } from 'reduxState/user';
import { setCatalog } from 'reduxState/catalog';

import paths from 'constants/paths';
import { formatPrice } from 'utils/cart-utils';
import { addDays } from 'utils/date-utils';
import { cartAddItem } from 'utils/klaviyo-utils';
import itemAvailabilities from 'constants/itemAvailabilities';
import shopCategories from 'constants/shopCategories';
import { trackEnhancedAddToCart, trackEnhancedRemoveFromCart } from 'utils/googleTagManager';

const { OUT_OF_SEASON } = itemAvailabilities;
const { GIFT } = shopCategories;

/**
 * * Redux Middleware for Cart actions
 *
 */

// list of actions for this middleware
const CART_PREVIEW_ACTIONS = [
  addItem.toString(),
  removeItem.toString(),
  removeItemOfType.toString(),
  removeItemsOfCategories.toString(),
  updateItem.toString(),
  setDiscount.toString(),
  removeDiscount.toString(),
  addBulkItems.toString(),
  addBulkItemsAndDiscount.toString(),
  addBulkItemsAndDiscountArray.toString(),
  setMyFarm.toString(),
  setCatalog.toString(),
  openCartModal.toString(),
  openOrderSummaryModal.toString(),
  closeModal.toString(),
  userLogout.toString(),
  loadCartPreview.toString(),
];

const cartMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  // ACTIONS THAT SHOULD BE PROCESSED BEFORE HITTING THE REDUCER
  const cartPreReducer = getState().cart;

  if (action.type === removeItem.toString()) {
    const itemRemoved = cartPreReducer.items.find((item) => item.sku === action.payload);

    trackEnhancedRemoveFromCart({ products: [{ itemRemoved }] });
  }

  // PASS ACTION TO THE REDUCER!!!
  next(action);

  // Middleware only for cart changes
  if (!CART_PREVIEW_ACTIONS.includes(action.type)) return;

  // get cart and user to send info to cartPreview
  const cart = getState().cart;
  const user = getState().user;
  const reservation = getState().reservation;
  const capiUserData = getCAPIUserInfo(getState());

  // Only run cart preview if 1. Cart modal is open or 2. we're on checkout or 3. we're setting a promo code
  if (
    !!cart.sideModalToShow ||
    (window.location.pathname === paths.CHECKOUT && action.type !== closeModal.toString()) ||
    action.type === setDiscount.toString() ||
    action.type === loadCartPreview.toString()
  ) {
    const cartToSend = {
      items: cart.items
        .filter((item) => item?.sku && item?.qty)
        .map((item) => {
          // Return full item if it is a Gift Card for meta data
          if (item.category === GIFT)
            return {
              sku: item.sku,
              qty: item.qty,
              meta: {
                imageUrl: item.imageUrl,
                amountCents: item.priceCents,
              },
            };

          // Backend only needs sku and qty for each item.
          return {
            sku: item.sku,
            qty: item.qty,
          };
        }),
    };

    if (user.zip) cartToSend.zip = user.zip;
    else cartToSend.zip = '73301';
    cartToSend.discounts = cart.discounts;

    // if there is an URL promocode and no manual promocode was added, then send URL promocode to BE
    const manualDiscounts = cart.manualDiscounts.filter((e) => !(action.type === removeDiscount.toString() && e === action.payload));
    if (!manualDiscounts.length && !!cart.urlDiscount) {
      cartToSend.discounts = { [cart.urlDiscount]: 0, ...cartToSend.discounts };
    }

    if (reservation.code) cartToSend.reservation = reservation.code;

    const isLoggedIn = getLoginState(getState());

    dispatch(cartPreview(cartToSend, isLoggedIn));
  }

  // Manage scrolling when toggling the sideModal
  if (cart.sideModalToShow) {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.overscrollBehavior = 'none';
  }

  if (action.type === closeModal.toString()) {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.overscrollBehavior = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  // Push Viewcart GTM event when a user removes an item
  if (action.type === removeItem.toString()) {
    window.dataLayer?.push({
      event: 'Cartview',
      url: '/modal/cart',
      itemsInCart: cart.items.map((item) => Object.assign({}, item, { priceCents: formatPrice(item.priceCents / 100, 2, false) })),
      totalCents: formatPrice(cart.totalCents / 100, 2, false),
    });
  }

  if (action.type === addItem.toString()) {
    const trackItemName = action.payload.item.name?.replace?.('™', '');
    // *** Google Analytics Actions *** //

    trackEnhancedAddToCart({
      products: [
        {
          name: trackItemName,
          sku: action.payload.item.sku,
          priceCents: action.payload.item.priceCents,
          category: action.payload.item.category,
          qty: 1,
        },
      ],
      capiData: capiUserData,
    });
    cartAddItem({ item: action?.payload?.item, cart, supplies: getState().catalog.supplies });
  }
  if (
    action.type === addBulkItems.toString() ||
    action.type === addBulkItemsAndDiscount.toString() ||
    action.type === addBulkItemsAndDiscountArray.toString() ||
    action.type === replaceSeedlings.toString()
  ) {
    for (let item of action.payload.items) {
      const trackItemName = item.name?.replace?.('™', '');
      trackEnhancedAddToCart({
        products: [
          {
            name: trackItemName,
            sku: item.sku,
            priceCents: item.priceCents,
            category: item.category,
            qty: item.qty,
          },
        ],
        capiData: capiUserData,
      });
      cartAddItem({ item, cart, supplies: getState().catalog.supplies });
    }
  }
  if (action.type === addBulkItemsAndDiscount.toString() || action.type === addBulkItemsAndDiscountArray.toString()) {
    (action?.payload?.items || []).forEach((item) => {
      cartAddItem({ item, cart, supplies: getState().catalog.supplies });
    });
  }

  // remove items that are not found in catalog
  if (action.type === setCatalog.toString()) {
    const cart = getState().cart;
    const appSettings = getState().appSettings;
    const catalog = action.payload;
    const isFarmstandInCart = getCartHasFarmstand(getState());
    const buyableDate = isFarmstandInCart ? addDays(Date.now(), parseInt(appSettings.outSeedsBuyableIn)) : Date.now();

    /* HEADS UP - to determine if a seedling is out of stock,
     ** we take into account whether cart has farmstand (FYF), and if so, allow a more generous buyable window
     ** we also account for a stale catalog response from a long running session and check that the inStockDate is in the future, before removing
     */

    cart.items.forEach((item) => {
      const catalogSeed = catalog.plantTypes[item.sku];
      const isSeedlingReserved = reservation.items.find((resItem) => resItem.sku === item.sku) && reservation.expiry > Date.now();
      const isSeedlingOutOfStock = !!catalogSeed?.inStockDate && catalogSeed?.inStockDate > buyableDate;
      const isOutOfSeason = catalogSeed?.availability === OUT_OF_SEASON && !catalogSeed?.inStockDate && !catalogSeed?.inSeasonDate;
      const isSeedFutureShipBuyable = !!catalogSeed?.shipsOnDate;

      if (
        (!catalog.plantTypes[item.sku] || isSeedlingOutOfStock || isOutOfSeason) &&
        !catalog.deviceTypes[item.sku] &&
        !catalog.buyables[item.sku] &&
        !isSeedlingReserved &&
        !isSeedFutureShipBuyable
      ) {
        // remove stale item - local storage is updated accordingly from middleware
        dispatch(removeItem(item.sku));
      }
    });
  }
};

export default cartMiddleware;
