import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { QtyControl, AddToBuyNowStyled } from './AddToBuyNow.styled';
import { Text } from 'elements';

import { getItemInBuyNow, addItemBuyNow, updateItemBuyNow, removeItemBuyNow } from 'reduxState/cart';
import { getItemInResevation } from 'app/reduxState/reservation/reservation';
import { useBreakpoint } from 'utils/hooks';
import { formatDynamicDecimalPrice } from 'utils/cart-utils';

const AddToBuyNow = ({
  item,
  label,
  isDisabled,
  isSoldOut,
  isOutOfSeason,
  outOfSeasonLabel,
  isLoading,
  showPrice,
  modifiers,
  disabledModifier,
  isTrackingEvent,
}) => {
  const buyNowItem = useSelector((state) => getItemInBuyNow(state, item.sku));
  const reservedItem = useSelector((state) => getItemInResevation(state, item.sku));
  const dispatch = useDispatch();
  const screenSize = useBreakpoint();
  const itemQty = (buyNowItem && buyNowItem?.qty) || 0;
  const hasAnyItems = itemQty > 0;
  const soldOutLabel = isOutOfSeason ? outOfSeasonLabel : 'SOLD OUT';
  const isSoldOutOrOutOfSeason = isSoldOut || isOutOfSeason;

  const addToBuyNow = () => {
    const itemToAdd = {
      sku: item.sku,
      qty: 1,
    };
    dispatch(addItemBuyNow({ item: itemToAdd }));
  };

  const incrementQty = () => {
    const itemToUpdate = {
      sku: item.sku,
      qty: buyNowItem.qty + 1,
    };

    dispatch(updateItemBuyNow(itemToUpdate));
  };

  const decrementQty = () => {
    if (buyNowItem.qty === 1) {
      dispatch(removeItemBuyNow(item.sku));
    } else {
      const itemToUpdate = {
        sku: item.sku,
        qty: buyNowItem.qty - 1,
      };
      dispatch(updateItemBuyNow(itemToUpdate));
    }
  };
  return (
    <>
      {hasAnyItems && !isLoading ? (
        <QtyControl
          onDecrease={decrementQty}
          onIncrease={incrementQty}
          disabledIncrease={reservedItem && reservedItem.max && itemQty >= reservedItem.max}
          quantity={itemQty}
          isActive={!isDisabled && (!isSoldOutOrOutOfSeason || !!reservedItem)}
          modifiers={screenSize === 'SM' && 'fluid'}
        />
      ) : (
        <AddToBuyNowStyled
          label={
            isSoldOutOrOutOfSeason && !reservedItem
              ? soldOutLabel
              : isLoading
              ? '...ADDING TO CART'
              : `${label} ${showPrice && item.priceCents ? formatDynamicDecimalPrice(item.priceCents) : ''}`
          }
          onClick={addToBuyNow}
          eventData={isTrackingEvent ? { action: `${label}: ${item.name}` } : {}}
          disabled={isDisabled}
          modifiers={[
            ...modifiers,
            screenSize === 'SM' && 'fluid',
            (isDisabled || (isSoldOutOrOutOfSeason && !reservedItem) || isLoading) && (disabledModifier || 'disabled'),
          ]}
        />
      )}
      {reservedItem && reservedItem.max && <Text modifiers={['small', 'errorColor']}>{`Maximum of ${reservedItem.max}`}</Text>}
    </>
  );
};

AddToBuyNow.defaultProps = {
  isDisabled: false,
  isLoading: false,
  showPrice: true,
  item: {},
  isSoldOut: false,
  isOutOfSeason: false,
  outOfSeasonLabel: 'OUT OF SEASON',
  label: 'ADD TO CART',
  modifiers: [],
  disabledModifier: 'disabled',
  isTrackingEvent: true,
};

AddToBuyNow.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  showPrice: PropTypes.bool,
  item: PropTypes.shape({
    sku: PropTypes.string,
    qty: PropTypes.number,
    name: PropTypes.string,
    priceCents: PropTypes.number,
  }),
  isSoldOut: PropTypes.bool,
  isOutOfSeason: PropTypes.bool,
  outOfSeasonLabel: PropTypes.string,
  label: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabledModifier: PropTypes.string,
  isTrackingEvent: PropTypes.bool,
};

export default AddToBuyNow;
