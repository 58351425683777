import React from 'react';
import PropTypes from 'prop-types';

import { Swiper, AddToCart } from 'components';

import Modal from '../Modal';

import {
  ModalQuickViewWrapper,
  SwipeItem,
  SwiperWrapper,
  ImageWrapper,
  SwiperItemWrapContent,
  SwiperItemContent,
  SeedlingInfoDetails,
  SeedlingFeatures,
  WrapCTA,
} from './QuickViewModal.styled';
import { Title, Link, Text, Image } from 'elements';
import { getItemPath } from 'utils/cart-utils';
import { useContent, useBreakpoint } from 'utils/hooks';
import { isSeedOutOfStock } from 'utils/seedlings-utils';
import { PLANT_TYPE } from 'constants/contentful';
import { useLocation } from '@reach/router';
import { paths } from 'constants';
import AddToBuyNow from 'components/add-to-buy-now/AddToBuyNow';

const PLACEHOLDER_IMAGE =
  'https://images.ctfassets.net/tjhwhh07rwer/4vtw9IJNQadFm0nJHWXPE0/5ccf4d2d25d85e3cbc548b3b20f71a57/SeedlingSide.jpeg';

const QuickViewModal = ({ show, catalog, showCTA, onCloseAction }) => {
  const plantsContent = useContent(PLANT_TYPE);
  const size = useBreakpoint();
  const location = useLocation();

  const { plants } = catalog;

  const swiperConfig = {
    loop: false,
    spaceBetween: 12,
    slidesPerView: 1,
    centeredSlides: true,
    pagination: true,
    navigation: plants.length > 1,
  };

  const isBundle = plants.length > 1 ? catalog : plants[0];
  const isOutOfStock = isSeedOutOfStock(isBundle);
  const isDesktop = size === 'LG' || size === 'XL';

  const isRecommendedOrder = location.pathname.includes(paths.PERSONALIZE_RECOMMENDATION);

  if (!show) return null;

  const renderSwipeItem = (item, key) => {
    const path = getItemPath(item);
    const currentSeedling = plantsContent.filter((plant) => plant.sys.id === item.sku);
    const seedlingPicture = item?.imageUrl || PLACEHOLDER_IMAGE;

    const { friendlyName, minDaysInDevice, maxDaysInDevice, introSentence, seasonality, harvestMethod, devicePlacement } =
      currentSeedling?.[0]?.fields || {};
    const minHarvestWeeks = Math.floor((minDaysInDevice || 7) / 7);
    const maxHarvestWeeks = Math.ceil((maxDaysInDevice || 14) / 7);

    return (
      <SwipeItem key={key}>
        <ImageWrapper>
          {!!seedlingPicture && <Image alt={friendlyName} picture={seedlingPicture} nativeWidth={isDesktop ? 495 : 166} />}
        </ImageWrapper>
        <SwiperItemWrapContent>
          <SwiperItemContent>
            {catalog.name && (
              <Text as='h4' modifiers={['brandFont', 'lightGrayColor', 'small', 'uppercase', 'semibold']} className='BundleName'>
                {catalog.name} - {key + 1}/{plants.length} PLANTS
              </Text>
            )}
            {friendlyName && <Title as='h2'>{friendlyName}</Title>}
            <Text className='IntroSentence' modifiers={['brandFont']}>
              {introSentence}
            </Text>
            <Text className='LabelGrowing' modifiers={['brandFont', 'uppercase']}>
              you will be growing:
            </Text>
            <SeedlingInfoDetails>
              {seasonality && (
                <SeedlingFeatures>
                  <Text modifiers={['brandFont', 'left']}>
                    Growing Season:&nbsp;
                    <Text as='strong'>{seasonality}</Text>
                  </Text>
                </SeedlingFeatures>
              )}
              {harvestMethod && (
                <SeedlingFeatures>
                  <Text modifiers={['brandFont', 'left']}>
                    Harvest Method:&nbsp;
                    <Text as='strong'>{harvestMethod}</Text>
                  </Text>
                </SeedlingFeatures>
              )}
              <SeedlingFeatures>
                <Text modifiers={['brandFont', 'left']}>
                  First Harvest:&nbsp;
                  <Text as='strong'>{minHarvestWeeks} weeks</Text>
                </Text>
              </SeedlingFeatures>
              <SeedlingFeatures>
                <Text modifiers={['brandFont', 'left']}>
                  Final Harvest:&nbsp;
                  <Text as='strong'>{maxHarvestWeeks} weeks</Text>
                </Text>
              </SeedlingFeatures>
              {devicePlacement && (
                <SeedlingFeatures>
                  <Text modifiers={['brandFont', 'left']}>
                    Best Farmstand Placement:&nbsp;
                    <Text as='strong'>{devicePlacement}</Text>
                  </Text>
                </SeedlingFeatures>
              )}
            </SeedlingInfoDetails>
            {plants.length === 1 && (
              <Link href={path} modifiers={['brandColor', 'brandFont', 'medium', 'underline']}>
                Learn More
              </Link>
            )}
          </SwiperItemContent>
          {showCTA && (
            <WrapCTA>
              {isRecommendedOrder ? (
                <AddToBuyNow item={isBundle} isSoldOut={isOutOfStock} />
              ) : (
                <AddToCart item={isBundle} isSoldOut={isOutOfStock} />
              )}
            </WrapCTA>
          )}
        </SwiperItemWrapContent>
      </SwipeItem>
    );
  };

  return (
    <ModalQuickViewWrapper>
      <Modal isOpen={show} onCloseAction={onCloseAction}>
        <SwiperWrapper>
          <Swiper items={plants} renderSlideCard={renderSwipeItem} swiperProps={swiperConfig} />
        </SwiperWrapper>
      </Modal>
    </ModalQuickViewWrapper>
  );
};

QuickViewModal.propTypes = {
  show: PropTypes.bool.isRequired,
  catalog: PropTypes.object,
  showCTA: PropTypes.bool,
  onCloseAction: PropTypes.func,
};

export default QuickViewModal;
