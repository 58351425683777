import React, { useRef, useState, useEffect } from 'react';
import { ButtonText, Text, Icon, SquaredButton } from 'elements';
import { Flex } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogSeedlings } from 'reduxState/catalog';
import {
  openCartModal,
  getIsBuyNowLoaded,
  loadBuyNowPreview,
  getBuyNow,
  addBulkItems,
  replaceSeedlings,
  getNumSeedlingsInBuyNow,
  getNumSeedlingsInCart,
  clearCart,
} from 'reduxState/cart';
import {
  getUserAuthToken,
  fetchMyOrders,
  getUserOrders,
  fetchUserDevices,
  getAreUserDevicesFetched,
  getNumSeedlingsInSuggestedPlants,
} from 'reduxState/user';
import { CHOOSE_FOR_ME_MODAL } from 'constants/contentful';
import { formatPrice } from 'utils/cart-utils';
import { axiosPost } from 'utils/api-utils';
import { useContent, useSwipe, useBreakpoint } from 'utils/hooks';
import { useLocation } from '@reach/router';

import ChevronLeft from 'elements/icon/assets/left.svg';
import Chevron from 'elements/icon/assets/chevron.svg';
import LoadingIcon from 'assets/images/loading.svg';

import { navigate } from 'gatsby';
import paths from 'constants/paths';
import { setOpenModal } from 'reduxState/modal';
import { ErrorModal, SelectBundleModal } from 'components/modal';

import {
  FixedOneClickPayment,
  SectionAnimated,
  SectionTop,
  SectionMiddle,
  SectionBottom,
  WrapOrderBtn,
  BgOverlay,
  SummaryOrder,
  WrapLoading,
  WrapPersonalize,
} from './StickyOrderSummary.styled';

const StickyOrderSummary = () => {
  const [showChooseModal, setShowChooseModal] = useState(false);
  const [disableTotalCTA, setDisableTotalCTA] = useState(false);
  const dispatch = useDispatch();
  const modalChooseContent = useContent(CHOOSE_FOR_ME_MODAL);
  const userAuthToken = useSelector(getUserAuthToken);
  const seedlings = useSelector(getCatalogSeedlings);
  const buyNow = useSelector(getBuyNow);
  const isBuyNowLoaded = useSelector(getIsBuyNowLoaded);
  const totalSeedlings = useSelector(getNumSeedlingsInSuggestedPlants);
  const totalSeedlingsBuyNow = useSelector(getNumSeedlingsInBuyNow);
  const [showSummary, setShowSummary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const summaryRef = useRef(null);
  const location = useLocation();
  const breakpoint = useBreakpoint();
  const seedlingsInCartQty = useSelector(getNumSeedlingsInCart);
  const areDevicesFetched = useSelector(getAreUserDevicesFetched);
  const userOrders = useSelector(getUserOrders);

  useSwipe(summaryRef, setShowSummary);

  const toggleChooseModal = () => {
    setShowChooseModal(!showChooseModal);
  };

  const chooseForMe = (type) => () => {
    const items = buyNow.items.map((item) => ({
      ...seedlings[item.sku],
      qty: item.qty,
    }));

    const action = type === 'replace' ? replaceSeedlings({ items }) : addBulkItems({ items });
    dispatch(action);
    if (showChooseModal) toggleChooseModal();
    dispatch(openCartModal());
  };

  const goToOpenCart = () => {
    if (seedlingsInCartQty > 0) {
      toggleChooseModal();
    } else {
      chooseForMe('add')();
    }
    setShowSummary(false);
  };

  const processPayment = () => {
    setIsLoading(true);
    const params = {
      items: buyNow.items,
    };

    axiosPost('app/lgmobile/cartCheckout', params, {}, userAuthToken)
      .then((response) => {
        setIsLoading(false);
        if (response) {
          //clean up the buyNow state
          dispatch(clearCart());
          navigate(paths.ORDER_CONFIRMATION + `/${response.data.orderNumber}`);
        } else {
          dispatch(setOpenModal('error'));
        }
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(setOpenModal('error'));
      });
  };

  useEffect(() => {
    dispatch(fetchMyOrders());
  }, []);

  useEffect(() => {
    if (!areDevicesFetched) dispatch(fetchUserDevices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!isBuyNowLoaded && totalSeedlingsBuyNow > 0) {
      dispatch(loadBuyNowPreview());
    }
  }, [dispatch, isBuyNowLoaded, totalSeedlingsBuyNow]);

  useEffect(() => {
    if (totalSeedlingsBuyNow === 0) {
      setDisableTotalCTA(true);
    } else {
      setDisableTotalCTA(false);
    }
  }, [totalSeedlingsBuyNow]);

  const subTotal = buyNow.subtotalCents / 100;
  const shipping = buyNow.shippingCents / 100;
  const tax = buyNow.taxCents / 100;
  const total = buyNow.totalCents / 100;

  const isFYF = location.pathname.includes(paths.PERSONALIZE_RECOMMENDATION);

  const CCOnFile = userOrders?.cards?.[0] || {};

  return (
    <>
      <ErrorModal />
      <SelectBundleModal
        show={showChooseModal}
        title={modalChooseContent?.[0]?.fields?.title || ''}
        body={modalChooseContent?.[0]?.fields?.body || ''}
        onReplace={chooseForMe('replace')}
        onAdd={chooseForMe('add')}
        onCloseAction={toggleChooseModal}
      />

      <BgOverlay show={showSummary} onClick={() => setShowSummary(false)} />

      <FixedOneClickPayment className={showSummary ? 'show' : ''} ref={summaryRef}>
        <SectionAnimated>
          <SectionTop>
            <Icon modifiers={['xSmall', !showSummary && 'rotate180']} onClick={() => setShowSummary(!showSummary)}>
              <Chevron />
            </Icon>
            <Text
              modifiers={['brandFont', 'center', 'noLetterSpace']}
              content={breakpoint !== 'SM' && breakpoint !== 'MD' ? 'ORDER SUMMARY' : 'SWIPE UP FOR ORDER SUMMARY'}
            />
          </SectionTop>
          <SectionMiddle>
            <SummaryOrder>
              {buyNow.items.length > 0 &&
                buyNow.items.map((item) => (
                  <Flex modifiers={['spaceBetween']} className='OrderDetail' key={item.sku}>
                    <Text modifiers={['brandFont', 'noLetterSpace']} className='Qty'>
                      {item.qty}
                    </Text>
                    <Text modifiers={['brandFont', 'noLetterSpace']} className='Name'>
                      {item.name}
                    </Text>
                    <Text modifiers={['brandFont', 'noLetterSpace']} className='Price'>
                      {formatPrice((item.priceCents * item.qty) / 100)}
                    </Text>
                  </Flex>
                ))}

              <hr />
              <Flex modifiers={['spaceBetween']} className='SubTotal'>
                <Text modifiers={['brandFont', 'noLetterSpace', 'bold']}>SUBTOTAL</Text>
                <Text modifiers={['brandFont', 'noLetterSpace', 'bold']}>{formatPrice(subTotal)}</Text>
              </Flex>
              <Flex modifiers={['spaceBetween']} className='Shipping'>
                <Text modifiers={['brandFont', 'noLetterSpace']}>SHIPPING</Text>
                <Text modifiers={['brandFont', 'noLetterSpace']}>{formatPrice(shipping)}</Text>
              </Flex>
              <Flex modifiers={['spaceBetween']} className='Tax'>
                <Text modifiers={['brandFont', 'noLetterSpace']}>
                  ESTIMATED TAX <small>(based on zip code {buyNow?.customerZip})</small>
                </Text>
                <Text modifiers={['brandFont', 'noLetterSpace']}>{formatPrice(tax)}</Text>
              </Flex>
              <Flex modifiers={['spaceBetween']} className='Total'>
                <Text modifiers={['brandFont', 'noLetterSpace', 'brandColor']}>TOTAL</Text>
                <Text modifiers={['brandFont', 'noLetterSpace', 'brandColor']}>{formatPrice(total)}</Text>
              </Flex>
            </SummaryOrder>
          </SectionMiddle>
        </SectionAnimated>

        <SectionBottom isFYF={isFYF}>
          <ButtonText
            id='BackToFarm'
            onClick={() => {
              const backPath = isFYF ? paths.RECOMMENDED_ORDER_DETAILS : `${paths.MY_FARM}/#my-recommended-order`;
              navigate(backPath);
            }}
          >
            <Icon modifiers='small'>
              <ChevronLeft />
            </Icon>
            <Text content={isFYF ? 'BACK' : 'BACK TO MY FARM'} modifiers={['brandFont']} />
          </ButtonText>

          {isFYF && (
            <WrapPersonalize>
              <Text modifiers={['brandFont', 'noLetterSpace']} className='Label'>
                PERSONALIZE YOUR ORDER
              </Text>
              <Text modifiers={['brandFont', 'noLetterSpace', 'brandColor']} className='Amount'>
                {totalSeedlingsBuyNow} / {totalSeedlings}
              </Text>
            </WrapPersonalize>
          )}

          <WrapOrderBtn>
            {!isLoading && isBuyNowLoaded ? (
              CCOnFile?.id ? (
                <SquaredButton
                  className='SquaredButton'
                  modifiers={['tertiary', disableTotalCTA ? 'disabled' : '']}
                  label='ORDER NOW'
                  onClick={processPayment}
                >
                  <span>{formatPrice(total)}</span>
                </SquaredButton>
              ) : (
                <SquaredButton
                  className='SquaredButton'
                  onClick={goToOpenCart}
                  label='ADD TO CART'
                  modifiers={['tertiary', disableTotalCTA ? 'disabled' : '']}
                  dataNw='checkout-button'
                >
                  <span>{formatPrice(total)}</span>
                </SquaredButton>
              )
            ) : (
              <WrapLoading>
                <LoadingIcon />
              </WrapLoading>
            )}
            {CCOnFile?.id && (
              <Text modifiers={['brandFont', 'small', 'noLetterSpace']}>
                Your Credit Card ending in <strong>{CCOnFile?.last4}</strong> will be charged
              </Text>
            )}
          </WrapOrderBtn>
        </SectionBottom>
      </FixedOneClickPayment>
    </>
  );
};

export default StickyOrderSummary;
