import React from 'react';
import PropTypes from 'prop-types';
import { navigate, graphql, StaticQuery } from 'gatsby';

import { useDispatch, useSelector } from 'react-redux';
import { closeModal, getNumSeedlingsInCart, getFarmstandsInCart, getCartItems } from 'reduxState/cart';
import _get from 'lodash/get';
import paths from 'constants/paths';
import { ButtonText } from 'elements';

import { SuggestionStyled, SuggestionInner } from './Suggestion.styled';

const Suggestion = ({ data }) => {
  const items = useSelector(getCartItems);
  const seedlingsInCartQty = useSelector((state) => getNumSeedlingsInCart(state)) || 0;
  const farmstandsInCart = useSelector(getFarmstandsInCart);
  const dispatch = useDispatch();
  let emptyElement = <></>;
  const farmstandModels = data.contentfulSectionFarmstand.farmstandModels;

  if (!farmstandModels.length || !items.length) return null;
  if (farmstandsInCart.length === 0) return emptyElement;

  const totalFarmstandsCapacity = farmstandsInCart.reduce((total, { sku, qty }) => {
    const model = farmstandModels.find((f) => _get(f, 'contentful_id') === sku);
    const sizes = (_get(model, 'size') || 0) * qty;
    return total + sizes;
  }, 0);

  const seedlingText = seedlingsInCartQty === 1 ? `seedling` : `seedlings`;
  const farmstandText = farmstandsInCart.length > 1 || farmstandsInCart[0].qty > 1 ? `Farmstands hold` : `Farmstand holds`;
  if (seedlingsInCartQty === totalFarmstandsCapacity) return emptyElement;

  return (
    <SuggestionStyled>
      <SuggestionInner>
        <p>
          You have <strong>{seedlingsInCartQty}</strong> {seedlingText} and your {farmstandText} <strong>{totalFarmstandsCapacity}</strong>.
          <br />
          <ButtonText
            as='a'
            label={seedlingsInCartQty < totalFarmstandsCapacity ? 'ADD MORE SEEDLINGS' : 'EDIT SEEDLINGS'}
            onClick={() => {
              dispatch(closeModal());
              navigate(paths.FILL_YOUR_FARMSTAND_BUNDLES);
            }}
          />
        </p>
      </SuggestionInner>
    </SuggestionStyled>
  );
};

const query = graphql`
  query {
    contentfulSectionFarmstand(contentful_id: { eq: "1368lss8SXKqO26v9USf0B" }) {
      id
      name
      farmstandModels {
        size
        contentful_id
      }
    }
  }
`;

const SuggestionDataWrapper = (props) => (
  <StaticQuery
    query={query}
    render={(data) => {
      return <Suggestion data={data} {...props} />;
    }}
  />
);

Suggestion.defaultProps = {
  data: {
    contentfulSectionFarmstand: {
      farmstandModels: [],
    },
  },
};

Suggestion.propTypes = {
  data: PropTypes.shape({
    contentfulSectionFarmstand: PropTypes.shape({
      farmstandModels: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.number,
          contentful_id: PropTypes.string,
        })
      ),
    }),
  }),
};

export default SuggestionDataWrapper;
