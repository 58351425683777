/**
 * * Footer Component for the Cart on Lettuce Grow
 *
 * @prop {function} closeModal - the function that will close the modal in which the cart lives
 * @prop {object} cart - an object from our redux store representing all cart information
 *
 * ? Should this be refactored to be extensible and used as the Order Summary footer?
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { navigate } from 'gatsby';

import { getNotEnoughSeedlingsInCart, getCartItems, getCartHasSubscriptionAndGift } from 'reduxState/cart';
import paths from 'constants/paths';
import oldBreakpoints from 'constants/breakpoints';
import { trackEnhancedCheckout } from 'utils/googleTagManager';

import { SquaredButton } from 'elements';

import Suggestion from '../suggestion/Suggestion';
import GiftcardSubscriptionSuggestion from '../giftcard-subscription-suggestion/Suggestion';
import Total from '../total/Total';

const FooterContainer = styled.section.attrs({
  className: 'FooterContainer',
})`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 1.25rem;
  display: block;
  background: ${({ theme }) => theme.farmProject.gray_2};
  box-sizing: border-box;
  z-index: 1;

  box-shadow: 0px -8px 20px -18px rgba(0, 0, 0, 0.5);
`;

const FooterActions = styled.section`
  text-align: center;

  div {
    padding: 0;

    button {
      width: 100%;
      margin: 10px 0;
    }
  }

  .MoreSeedlings {
    padding: 0.5rem 0.125rem;
  }

  > p {
    font-size: 0.75rem;
    font-weight: normal;
    font-family: ${({ theme }) => theme.farmProject.font_family};
    color: ${({ theme }) => theme.farmProject.dark_gray};
    padding-bottom: 18px;
    text-decoration: underline;
  }

  .keep-shopping {
    text-decoration: underline;
    font-weight: normal;
    font-family: ${({ theme }) => theme.farmProject.font_family};
    letter-spacing: 0.6px;
    color: ${({ theme }) => theme.farmProject.dark_gray};
    margin-top: 1.4rem;
    padding-bottom: 4rem;

    @media (min-width: ${oldBreakpoints.MOBILE}px) {
      padding-bottom: 18px;
    }
  }

  .shippingTaxes {
    letter-spacing: 1px;
    text-decoration: none;
  }
`;

const CartFooter = ({ closeModal }) => {
  const items = useSelector(getCartItems);
  const isNotEnoughSeedlings = useSelector(getNotEnoughSeedlingsInCart);
  const cartHasSubcriptionAndGift = useSelector(getCartHasSubscriptionAndGift);

  const closeModalAndPushToDataLayer = () => {
    closeModal();
    navigate(paths.CHECKOUT);
    trackEnhancedCheckout({ actionField: { step: 1 }, products: items });
  };

  const isCheckoutButtonDisabled = isNotEnoughSeedlings || cartHasSubcriptionAndGift;

  return (
    <FooterContainer>
      <Total />
      <FooterActions>
        <Suggestion />
        <GiftcardSubscriptionSuggestion />
        <SquaredButton
          onClick={closeModalAndPushToDataLayer}
          label='CHECKOUT'
          disabled={isCheckoutButtonDisabled}
          modifiers={[isCheckoutButtonDisabled && 'disabled', 'fluid', 'tertiary']}
          dataNw='checkout-button'
        />
      </FooterActions>
    </FooterContainer>
  );
};

CartFooter.propTypes = {
  closeModal: PropTypes.func,
};

export default CartFooter;
