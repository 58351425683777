import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { graphql, StaticQuery } from 'gatsby';

import { getCartHasSubscriptionAndGift } from 'reduxState/cart';

import { SuggestionStyled, SuggestionInner } from './Suggestion.styled';

const Suggestion = ({ data }) => {
  const cartHasSubcriptionAndGift = useSelector(getCartHasSubscriptionAndGift);
  const body = data.contentfulCalloutBlock.body;

  if (!cartHasSubcriptionAndGift || !body) return null;

  return (
    <SuggestionStyled>
      <SuggestionInner>
        <p>{body}</p>
      </SuggestionInner>
    </SuggestionStyled>
  );
};

const query = graphql`
  query {
    contentfulCalloutBlock(contentful_id: { eq: "3BLtTnLERc5EgjKyfXbxvL" }) {
      contentful_id
      body
    }
  }
`;

const SuggestionDataWrapper = (props) => (
  <StaticQuery
    query={query}
    render={(data) => {
      return <Suggestion data={data} {...props} />;
    }}
  />
);

Suggestion.defaultProps = {
  data: {
    contentfulCalloutBlock: {
      body: '',
    },
  },
};

Suggestion.propTypes = {
  data: PropTypes.shape({
    contentfulCalloutBlock: PropTypes.shape({
      body: PropTypes.string,
    }),
  }),
};

export default SuggestionDataWrapper;
