import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  minLoadingHeight: () => ({
    styles: `
      min-height: 70vh;
    `,
  }),
  fullLoadingHeight: () => ({
    styles: `
      min-height: 100vh;
    `,
  }),
};

export default styled.div`
  ${applyStyleModifiers(modifiers)};
`;
