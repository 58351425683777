import srcFarmstand from './the-farmstand.png';
import srcFarmstandHover from './the-farmstand-hover.png';
import srcSeedling from './seedling.png';
import srcSeedlingHover from './seedling-hover.png';
import srcSupplies from './supplies.png';
import srcSuppliesHover from './supplies-hover.png';
import srcGift from './gift-cards.png';
import srcGiftHover from './gift-cards-hover.png';

const menuImages = [
  {
    image: srcFarmstand,
    hover: srcFarmstandHover,
  },
  {
    image: srcSeedling,
    hover: srcSeedlingHover,
  },
  {
    image: srcSupplies,
    hover: srcSuppliesHover,
  },
  {
    image: srcGift,
    hover: srcGiftHover,
  },
  {
    image: srcGift,
    hover: srcGiftHover,
  },
];

export default menuImages;
